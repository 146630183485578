.footer {
  background: transparent;
  top: 10%;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  position: relative;  /* Changed to relative */
  box-sizing: border-box;
  font-size: 1rem;
}

.footer p {
  margin: 0;
  font-size: 1.2rem;
}

.footer .socials {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.footer .socials a {
  color: white;
  transition: 0.3s ease-in-out;
}

.footer .socials a:hover {
  transform: scale(1.1);
}

.footer .social-icon {
  width: 30px;
  height: 30px;
}
/* Basic styles for the body */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #000000, #0a0a23, #0d1440);
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Ensure the body takes full height */
  box-sizing: border-box;
}

/* Image container styling */
.image-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #e0e0e0;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 90%;
  margin-top: 5%;
}

#warningButton {
  width: 100%;         /* Ensures the image fits container */
  height: auto;        /* Keeps the aspect ratio */
  border-radius: 10px;
  margin-bottom: 20px;
  object-fit: cover;
}

/* Button styling */
.enter-button {
  font-size: 20px;
  padding: 15px 30px;
  background-color: #051457;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.enter-button:hover {
  background-color: #061558;
}

/* Ensuring smooth transition between loading and main content */
#content {
  display: none;
}

/* Centered content styling */
#content.content {
  display: block;
  width: 100%;
  max-width: 900px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

footer {
  text-align: center;
  padding: 10px 20px;
  background: #e0e0e0;
  color: #000;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 10px;
}

/* Contract container styling */
.contract-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  text-align: center;
}

.contract-container a {
  text-decoration: none; /* Remove underline */
}

.social-media-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}

.social-media-container a {
  color: #06195e;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-top: 15px;
}

.social-media-container a.tg-icon {
  color: #007bff;
}

.social-media-container a.twitter-icon {
  color: #1DA1F2;
}

.social-media-container img.social-icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.contract-address {
  font-size: 1rem;
  background: #06195e;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  width: 90%;
  max-width: 500px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.copy-button {
  background: #06195e;
  border: none;
  padding: 10px 15px;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
}

.copy-button:hover {
  background: #007bff;
}

/* Meet the team styling */
.meet-the-team {
  background-color: #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.team-members {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.team-member {
  width: 100px;
  text-align: center;
}

.team-member img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.team-member h3 {
  font-size: 0.9em;
  margin-top: 5px;
}

.team-member p {
  font-size: 0.8em;
  color: #666;
}

.memes {
  text-align: center;
  margin-top: 20px;
}

.memes h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.meme-images {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.carousel-image {
  width: 400px; /* Increased width */
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin: 0 10px;
}

.carousel-image.active {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel-image {
  animation: fadeIn 1s forwards;
}

/* Popup modal styling */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.popup-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.close-popup {
  position: absolute;
  top: -10px;
  right: 1px;
  background-color: transparent;
  color: red;
  font-weight: bold;
  font-size: 1.3rem;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 25%;
}

.close-popup:hover {
  background-color: darkred;
}

/* Main content styling */
.main {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.main h2 {
  color: #847964;
  font-size: 2rem;
  margin-top: 0;
}

/* Whitepaper section */
.whitepaper p {
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Remove bullet points and list styling */
.whitepaper ul {
  list-style: none;
  padding-left: 0;
}

.whitepaper li {
  padding-left: 0;
  margin-bottom: 10px;
}

/* Remove horizontal lines */
.whitepaper hr {
  display: none;
}

/* Responsive styles for header */
header {
  padding: 20px;
  text-align: center;
  width: 100%; 
  border-radius: 10px;
}

header img {
  width: 85%;
  height: auto;
  max-width: 100%; /* Adjust width for smaller screens */
  border-radius: 10px;
  margin-right: 5%;
}

headerMDX {
  padding: 20px;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  justify-content: center; /* Centers content vertically */
  margin-top: 20px;
}

headerMDX img {
  width: 25%;
  height: auto;
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

headerMDX h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

headerMDX a button {
  background-color: #06195e;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

headerMDX a button:hover {
  background-color: #007bff;
}

.dev  {
 color: white;
 
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .enter-button {
    font-size: 18px;
    padding: 12px 24px;
  }

  .main h2 {
    font-size: 1.8rem; /* Smaller headings */
  }

  .contract-address {
    font-size: 0.9rem;
    max-width: 90%;
  }

  /* Adjust image size in the container */
  #warningButton {
    width: 100%;
    height: auto;
  }

  .team-member img {
    width: 80px; /* Smaller images */
    height: 80px;
  }
}

/* For very small screens like mobile */
@media (max-width: 480px) {
  body {
    padding: 0 10px;
  }

  .image-container {
    margin-top: 10%;
    padding: 10px;
  }

  .enter-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .main {
    padding: 10px;
    width: 100%;
    margin-left: 15%;
  }

.whitepaper {
  margin-left: 15%;
}

.meet-the-team {
  background-color: #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-left: 5%;
}

  .team-member {
    width: 80px; /* More compact */
  }

  .team-member img {
    width: 70px;
    height: 70px;
  }

  footer {
    padding: 8px 10px;
    font-size: 0.8rem;
  }

  header img {
    width: 90%;
    max-width: 100%;
   margin-right: 8%;
  }
}

/* For extra small screens (375px and below) */
@media (max-width: 375px) {
  body {
    padding: 0 5px;
  }

.social-media-container {
  margin-left: 11%;
}

.contract-container {
  margin-left: 15%;
}

  .image-container {
    margin-top: 8%;
    padding: 5px;
  }

  .enter-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .main {
    padding: 5px;
    width: 100%;
  }

.memes {
  text-align: center;
  margin-top: 20px;
  margin-left: 10%;
}

.whitepaper {
  margin-left: 15%;
}

.meet-the-team {
  background-color: #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-left: 10%;
}

  .team-member {
    width: 60px;
  }

  .team-member img {
    width: 50px;
    height: 50px;
  }

  footer {
    padding: 5px 8px;
    font-size: 0.7rem;
  }

  .contract-address {
    font-size: 0.8rem;
    padding: 8px;
  }

  header img {
    width: 80%;
    margin-left: 10%;
  }
}
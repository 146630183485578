.faq-container {
  padding: 30px;
  color: white;
  background: linear-gradient(to bottom,  #0d1440);
  text-align: center;
}

.faq-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.faq-item {
  margin: 15px 0;
  text-align: left;
}

.faq-question {
  width: 100%;
  font-size: 1.2rem;
  background-color: #1c1c3c;
  color: white;
  padding: 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  text-align: left;
}

.faq-question:hover {
  background-color: #2d2d5c;
}

.faq-answer {
  background-color: #222;
  padding: 15px;
  margin-top: 5px;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.6;
}

.full-faq-link {
  display: inline-block;
  margin-top: 30px;
  padding: 12px 24px;
  background-color: blue;
  color: white;
  border-radius: 6px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.full-faq-link:hover {
  background-color: #3a3ad9;
}
.landing-container {
  margin-top: 1%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #000000, #0a0a23, #0d1440);
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1; /* Allow landing content to expand */
}

.under-construction {
  margin-top: 5%;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.button-container {
  margin: 20px 0;
}

.mdx-button {
  background: blue;
  color: white;
  font-size: 1.5rem;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.mdx-button:hover {
  background: blue;
}

.socials {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.socials a {
  color: white;
  transition: 0.3s ease-in-out;
}

.socials a:hover {
  transform: scale(1.1);
}

.social-icon {
  width: 31px;
  height: 31px;
}

/* Announcement Section */
.announcement {
  padding: 20px;
  margin: 20px 0;
  word-wrap: break-word; /* Ensures long words or URLs wrap */
}

.announcement h2 {
  font-size: 1.8rem;
  color: white;
  margin-bottom: 10px;
}

.announcement p {
  font-size: 1.2rem;
  color: white;
  line-height: 1.6;
  word-wrap: break-word; /* Ensures text inside paragraphs wrap as well */
}

.read-more {
  margin-top: 10px;
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: blue;
}

.mdx-image {
  max-width: 50%; /* Adjust this value as needed */
  height: 100%;     /* Maintain aspect ratio */
  border-radius: 10%;
}

.mdf-comingsoon {
  max-width: 50%;
  height: auto; /* Maintain the original aspect ratio */
  border-radius: 10%;
box-shadow: 0 0 4px 6px #8c18d4;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .landing-container {
    margin-top: 1%;
    padding: 15px;
  }

  .under-construction {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .mdx-button {
    font-size: 1.2rem;
    padding: 12px 25px;
  }

  .socials {
    gap: 15px;
    margin-top: 30px;
  }

  .social-icon {
  width: 32px;
  height: 32px;
  }

  .social-icon-gecko {
    width: 20px;
    height: 20px;
  }

  .announcement h2 {
    font-size: 1.6rem;
  }

  .announcement p {
    font-size: 1rem;
  }

 .mdx-image {
  max-width: 50%; /* Adjust this value as needed */
  height: 100%;     /* Maintain aspect ratio */
  border-radius: 10%;
  margin-top: 1%;
}

  .mdf-comingsoon {
    max-width: 80%;
    height: auto; /* Ensure aspect ratio is preserved */
  }
}

/* Extra Small Devices (Mobile) */
@media (max-width: 480px) {
  .landing-container {
    margin-top: 10%;
    padding: 10px;
  }

  .under-construction {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .mdx-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .socials {
    gap: 10px;
    margin-top: 20px;
  }

  .social-icon {
  width: 32px;
  height: 32px;
  }

  .social-icon-gecko {
    width: 20px;
    height: 20px;
  }

  .announcement h2 {
    font-size: 1.4rem;
  }

  .announcement p {
    font-size: 0.9rem;
  }

  .mdf-comingsoon {
    max-width: 90%;
    height: auto; /* Prevent stretching */
  }
}